<template>
	<div class="">
		<div class="gg-container">
			<tcMedicine></tcMedicine>
		</div>
	
	</div>
</template>

<script>
import tcMedicine from "./tcMedicine2.0";

export default {
	name: "TCMList",
  data () {
    return {};
  },
  components: {
    tcMedicine
  },
}
</script>

<style scoped lang="scss">


</style>
